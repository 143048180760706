import React, { useState } from 'react';
import '../business/apps/_create-app.scss';
import { useAppDispatch, useAppSelector } from '../../store/customHooks';

//form
import { useFormik } from 'formik';

//components
import Input from '../../components/input-fields/input/input';
import SlidingPane from '../../components/slidingpane/slidingpane';
import FillButton, {
  ButtonColor,
} from '../../components/common/buttons/button';
//utils
import { updateUserDetails } from './profile-details-schema';
import { IAccountState } from '../../store/account/account.interface';
import { updateUserDetailsAction } from '../../store/account/account.actions';
import { resetUpdatingAccount } from '../../store/account/account.reducer';
import ModalFooter from '../../components/common/modal/modal-footer';
import SuccessModal from '../../components/notice-modal/success-modal';
import FormWrapper from '../../components/form/form-wrapper';
import CustomPhoneInput from '../../components/input-fields/phone';

export interface EditCompanyProps {
  isOpen: boolean;
  onClose: Function;
}

function EditProfilePane(props: EditCompanyProps) {
  const { isOpen, onClose } = props;
  const dispatch = useAppDispatch();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { full_name, phone_number, email }: IAccountState = useAppSelector(
    (state) => state.account
  );
  const [formError, setFormError] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: full_name,
      phone_number: phone_number ?? '',
      email: email ?? '',
    },

    validationSchema: updateUserDetails,

    onSubmit: async (
      values: {
        full_name: string;
        phone_number: string;
        email: string;
      },
      formikHelper
    ) => {
      const payload = {
        full_name: values.full_name,
        phone_number: values.phone_number,
      };
      formikHelper.setSubmitting(true);
      setFormError('');
      await dispatch(updateUserDetailsAction(payload))
        .unwrap()
        .then(() => {
          handlePaneClose();
          setUpdateSuccess(true);
        })
        .catch((error) => {
          setFormError(error);
        })
        .finally(() => {
          formikHelper.setSubmitting(false);
        });
    },
  });

  const handlePaneClose = () => {
    onClose();
    setFormError('');
    dispatch(resetUpdatingAccount());
  };

  const handleCloseSuccess = () => {
    dispatch(resetUpdatingAccount());
    setUpdateSuccess(false);
  };

  return (
    <>
      <SlidingPane
        isOpen={isOpen}
        title="Edit Profile"
        onClose={handlePaneClose}
        footer={
          <ModalFooter>
            <FillButton
              variant="outlined"
              onClick={() => {
                handlePaneClose();
              }}
              className="close-button"
              colorVariant={ButtonColor.neutral}
              label="Close"
            />

            <FillButton
              type="submit"
              onClick={formik.submitForm}
              isLoading={formik.isSubmitting}
              disabled={!(formik.isValid && formik.dirty)}
              label="Save Details"
              loadingText="Saving"
            />
          </ModalFooter>
        }
      >
        <FormWrapper
          className="create-app-form"
          onSubmit={formik.handleSubmit}
          formError={formError}
          dismissFormError={() => setFormError('')}
        >
          <div className="form-section">
            <Input
              type="text"
              label="Full Name"
              placeholder="Full Name"
              name="full_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.full_name}
              error={formik.touched.full_name && formik.errors.full_name}
              errorMessage={formik.errors.full_name}
            />

            <CustomPhoneInput
              label="Phone Number"
              placeholder="Phone Number"
              name="phone_number"
              onChange={(value: string) => {
                formik.setFieldValue('phone_number', value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
              error={formik.touched.phone_number && formik.errors.phone_number}
              errorMessage={formik.errors.phone_number}
            />

            <div>
              <Input
                type="email"
                label="Email"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email}
                errorMessage={formik.errors.email}
                disabled
              />
              <p className="xxs">
                This can not be edited. Reach out to support if you need to
                change it.
              </p>
            </div>
          </div>
        </FormWrapper>
      </SlidingPane>

      <SuccessModal
        isOpen={updateSuccess}
        onClose={handleCloseSuccess}
        continueAction={handleCloseSuccess}
        title="Success"
        message="Updated successfully"
        description="Profile details was updated successfully"
      />
    </>
  );
}

export default EditProfilePane;
