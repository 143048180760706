export const Dictionary = {
  error_messages: {
    email_required: 'Your email is required',
    email_invalid: 'Please enter a valid email',
    password_required: 'Your password is required',
    password_min: 'Password must be at least 8 characters long',
    password_match:
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special case Character',
    full_name_required: 'Your name is required',
    description_required: 'A description is required',
    phone_number_required: 'Your phone number is required',
    phone_number_invalid: 'Please enter a valid phone number',
    url_required: 'Your website URL is required',
    url_invalid: 'Please enter a valid website URL',

    field_required: (name: string) => `${name} is required`,
  },
};
