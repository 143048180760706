import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from '../../../layout/authLayout/authLayout';
import './_forgot-password.scss';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { resetLogin } from '../../../store/auth/auth.reducer';
import { sendResetPasswordMailAction } from '../../../store/auth/auth.actions';

//components
import { useFormik } from 'formik';
import { ForgetPasswordSchema } from './forget-password-validation-schema';
import Input from '../../../components/input-fields/input/input';
import FillButton from '../../../components/common/buttons/button';

//images

import { ReactComponent as BackIcon } from '../../../assets/icons/arrow-back.svg';
import { IAuthState } from '../../../store/auth/auth.interface';
import urls from '../../../routes/all-routes';
import FormWrapper from '../../../components/form/form-wrapper';

export default function ForgotPasswordPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const resetState: IAuthState = useAppSelector((state) => state.auth);
  const [formError, setFormError] = React.useState('');

  const formik = useFormik({
    initialValues: { email: '' },

    validationSchema: ForgetPasswordSchema,

    onSubmit: async (values: { email: string }, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setFormError('');
      await dispatch(sendResetPasswordMailAction(values))
        .unwrap()
        .then(() => {
          navigate(urls.forgotPassword.sent, {
            state: { email: values.email },
          });
        })
        .catch((error) => {
          setFormError(error);
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    dispatch(resetLogin());
  }, []);

  return (
    <AuthLayout>
      <div className="forgot-password-page">
        <div>
          <h6>Forgot password?</h6>

          <p className="sm">
            No problem. Enter your email address so we’ll send you a link to
            reset your password.
          </p>
        </div>

        <FormWrapper
          onSubmit={formik.handleSubmit}
          formError={formError}
          dismissFormError={() => setFormError('')}
        >
          <Input
            label="Email Address"
            type="email"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('email', e.target.value);
              if (resetState.error || resetState.success) {
                dispatch(resetLogin());
              }
            }}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            errorMessage={formik.errors.email}
            error={formik.touched.email && formik.errors.email}
          />
          {resetState.error && !formik.errors.email && (
            <p>
              <label className="error-message">{resetState.error}</label>
            </p>
          )}

          <FillButton
            type="submit"
            isLoading={formik.isSubmitting}
            disabled={!(formik.dirty && formik.isValid)}
            label="Send Reset Link"
            className="auth-button"
          />
        </FormWrapper>

        <div className="sign-up">
          <Link to={urls.login.index}>
            <FillButton
              variant="ghost"
              icon={<BackIcon />}
              label="Back to Login"
            />
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
}
