import * as Yup from 'yup';
import {
  urlValidationErrorMessage,
  urlValidationRegex,
} from '../../../utils/helper';
import { Dictionary } from '../../../constants/dictionary';

export const updateCompanyDetails = Yup.object().shape({
  name: Yup.string().required(
    Dictionary.error_messages.field_required('Your business name')
  ),
  primary_email: Yup.string()
    .email(Dictionary.error_messages.email_invalid)
    .required(Dictionary.error_messages.email_required),
  support_email: Yup.string()
    .email(Dictionary.error_messages.email_invalid)
    .required(Dictionary.error_messages.email_required),
  support_phone: Yup.string().required(
    Dictionary.error_messages.phone_number_required
  ),
  address: Yup.string().required(
    Dictionary.error_messages.field_required('Address')
  ),
  website_url: Yup.string()
    .matches(urlValidationRegex, urlValidationErrorMessage)
    .notRequired(),
});

export const updateCompanyLogo = Yup.object().shape({
  logo_url: Yup.mixed(),
  short_logo_url: Yup.mixed(),
});
