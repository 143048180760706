import * as Yup from 'yup';
import { PasswordSchema } from '../../utils/helper';
import { Dictionary } from '../../constants/dictionary';

export const updateUserDetails = Yup.object().shape({
  full_name: Yup.string().required(
    Dictionary.error_messages.full_name_required
  ),
  email: Yup.string()
    .email('Invalid email')
    .required(Dictionary.error_messages.email_required),

  phone_number: Yup.string().required(
    Dictionary.error_messages.phone_number_required
  ),
});

export const updateUserImage = Yup.object().shape({
  logo_url: Yup.mixed(),
});

export const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required(
    Dictionary.error_messages.field_required('Your current password')
  ),
  new_password: PasswordSchema,
});
