import React, { useState } from 'react';
import '../../../pages/business/apps/_create-app.scss';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';

//form
import { useFormik } from 'formik';

//components
import Input from '../../input-fields/input/input';
import SlidingPane from '../../slidingpane/slidingpane';
import FillButton, { ButtonColor } from '../../common/buttons/button';
import SlidepaneStatus from '../../slidepane-status/slidepane-status';

//utils
import { IDecisionModels } from '../../../store/decision-model/decision-model.interface';
import { updateModelAction } from '../../../store/decision-model/decision-model.actions';
import ModalFooter from '../../common/modal/modal-footer';
import FormWrapper from '../../form/form-wrapper';

export interface EditCompanyProps {
  isOpen: boolean;
  onClose: Function;
}

function EditModelPane(props: EditCompanyProps) {
  const { isOpen, onClose } = props;
  const [formResponse, setFormResponse] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);
  const dispatch = useAppDispatch();
  const decisionModel: IDecisionModels = useAppSelector(
    (state) => state.decisionModel
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: decisionModel?.focusModel?.name,
      description: decisionModel?.focusModel?.description,
    },

    onSubmit: async (values) => {
      const modelUpdate = values;
      dispatch(
        updateModelAction({
          payload: modelUpdate,
          id: decisionModel.focusModel.id,
        })
      );
    },
  });

  return (
    <SlidingPane
      isOpen={isOpen}
      title="Model Details"
      onClose={onClose}
      footer={
        <ModalFooter>
          {formResponse?.type === 'success' && (
            <FillButton
              type="submit"
              onClick={() => {
                onClose();
                setFormResponse(null);
              }}
            >
              Close
            </FillButton>
          )}

          {formResponse?.type !== 'success' && (
            <React.Fragment>
              <FillButton
                variant="outlined"
                onClick={() => {
                  onClose();
                  setFormResponse(null);
                }}
                className="close-button"
                colorVariant={ButtonColor.secondary}
              >
                Close
              </FillButton>

              <FillButton
                type="submit"
                onClick={formik.submitForm}
                isLoading={decisionModel?.focusModelStatus === 'loading'}
                disabled={!(formik.isValid && formik.dirty)}
              >
                Save Details
              </FillButton>
            </React.Fragment>
          )}
        </ModalFooter>
      }
    >
      {formResponse?.type === 'success' && (
        <SlidepaneStatus
          onContinue={() => {
            onClose();
            setFormResponse(null);
          }}
          message={<span>Model Details Have Been Updated</span>}
        />
      )}

      {formResponse?.type !== 'success' && (
        <FormWrapper
          className="create-app-form"
          onSubmit={formik.handleSubmit}
          formError={formResponse?.message}
          dismissFormError={() => {
            setFormResponse(null);
          }}
        >
          <div className="form-section">
            <Input
              type="text"
              label="Name"
              placeholder="Model Name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              errorMessage={formik.errors.name}
            />

            <Input
              type="text"
              label="Description"
              placeholder="Description"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={formik.touched.description && formik.errors.description}
              errorMessage={formik.errors.description}
            />
          </div>
        </FormWrapper>
      )}
    </SlidingPane>
  );
}

export default EditModelPane;
