import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import { allTransactionsUrl, organizationWalletUrl } from '../../config/allUrl';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../utils/helper';

export const getWalletInfoAction = createAsyncThunk(
  'business/getWalletAction',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(organizationWalletUrl);
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getAllTransactionsAction = createAsyncThunk(
  'business/getAllTransactionsAction',
  async (
    pageDetails: {
      page?: number;
      limit?: number;
      [rest: string]: any;
    },
    ThunkApi
  ) => {
    if (pageDetails.page && pageDetails.page > 0) {
      pageDetails.page = pageDetails.page - 1;
    }

    let params = JSON.stringify(pageDetails);

    // CONVERT PARAMS TO QUERY PARAMS FOR CALL
    params = params
      .split(',')
      .join('&')
      .substring(1, params.length - 1)
      .replace(/"/g, '')
      .replace(/:/g, '=');

    try {
      const res = await callApi.get(`${allTransactionsUrl}?${params}`);
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
