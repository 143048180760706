import React, { useMemo, useState } from 'react';
import { DragAndDropInterface } from './interface';
import './_drag-and-drop.scss';

//icon
import FillButton, { ButtonColor } from '../common/buttons/button';
import { ReactComponent as PhotoIcon } from '../../assets/icons/photo-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';

function DragAndDrop({
  className,
  status,
  resetStatusAction,
  fileTypes,
  setFileToUpload,
  value,
  showPreview = false,
  handleChange,
  buttonLabelText = 'Add app image',
  reset,
  ...inputProps
}: DragAndDropInterface) {
  const [isUploading, setIsUploading] = useState(false);

  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState<{
    file: File | null;
    preview: string;
  }>({
    file: null,
    preview: '',
  });

  useMemo(() => {
    if (isUploading) {
      setProgress(0);
    }
  }, [isUploading]);

  useMemo(() => {
    if (progress >= 100 && isUploading) {
      setProgress(99);
    }
  }, [progress]);

  useMemo(() => {
    if (status === 'success' || status === 'error') {
      setIsUploading(false);
      if (resetStatusAction) {
        setTimeout(() => {
          resetStatusAction();
        }, 2000);
      }
    }
  }, [status]);

  useMemo(() => {
    if (reset) {
      setTimeout(() => {
        setFile({
          file: null,
          preview: '',
        });
        setFileToUpload(null);
        handleChange?.(null);
      }, 2000);
    }
  }, [reset]);

  const handleFileChange = (e: any) => {
    if (!e) {
      setFile({
        file: null,
        preview: '',
      });
      setFileToUpload(null);
      handleChange?.(null);
      return;
    }
    if (fileTypes && !fileTypes.includes(e.target.files[0].type)) {
      return;
    }

    setFile({
      file: e.target.files[0],
      preview: URL.createObjectURL(e.target.files[0]),
    });
    setFileToUpload(e.target.files[0]);

    const progressInterval = setInterval(() => {
      if (progress >= 100) clearInterval(progressInterval);
      setProgress((prevState) => prevState + 2);
    }, 100);
  };

  let maskClass = 'mask';
  if (status === 'success') {
    maskClass += ' success-mask';
  } else if (status === 'error') {
    maskClass += ' error-mask';
  }

  const formatFileTypes = () => {
    const allowedTypes: string[] = [];
    fileTypes?.split(',').forEach((type) => {
      allowedTypes.push(type.split('/')?.[1].toUpperCase() ?? type);
    });

    return allowedTypes.join(', ');
  };

  const is_preview_image_file = [
    'image/png',
    'image/jpg',
    'image/jpeg',
  ]?.includes?.(file.file?.type.toLowerCase() ?? '');

  const is_value_image_file =
    value?.includes?.('.png') ??
    value?.includes?.('jpg') ??
    value?.includes?.('jpeg');

  let content;
  if (value || (file.preview && showPreview)) {
    content = (
      <div className="uploaded-image">
        {is_preview_image_file && file.preview ? (
          <img src={file.preview} alt="" />
        ) : null}

        {!is_preview_image_file && file.preview ? (
          <object data={file.preview}></object>
        ) : null}

        {is_value_image_file && !file.preview && value ? (
          <img src={value} alt="" />
        ) : null}

        {!is_value_image_file && !file.preview && value ? (
          <object data={value}></object>
        ) : null}

        <div
          className="remove-image"
          onClick={() => {
            handleFileChange(null);
          }}
        >
          <DeleteIcon />
        </div>
        <div className="add-image-label">
          {(is_preview_image_file || is_value_image_file) && 'Image'} Preview
        </div>
      </div>
    );
  } else {
    content = (
      <div className="uploaded-image">
        <div>
          <PhotoIcon />
        </div>

        <div className="add-image-label">
          <div>
            <FillButton
              variant="outlined"
              colorVariant={ButtonColor.neutral}
              label={buttonLabelText}
              className="add-image-label-btn"
            />
          </div>
          <div style={{ marginTop: '0.5rem' }}>
            {formatFileTypes()} up to 5MB
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`drag-and-drop-container ${className ?? ''}`}>
      <input
        type="file"
        data-testid="file-upload-input"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleFileChange(e);
        }}
        accept={fileTypes}
        {...inputProps}
      />

      <div
        className={`mask ${
          value || file.preview ? 'image-value-present' : 'image-value-absent'
        } ${maskClass}`}
      >
        {content}
      </div>
    </div>
  );
}

export default DragAndDrop;
