import * as Yup from 'yup';
import {
  urlValidation,
  urlValidationErrorMessage,
} from '../../../utils/helper';
import { Dictionary } from '../../../constants/dictionary';

export const CreateAppSchema = Yup.object().shape({
  name: Yup.string().required(
    Dictionary.error_messages.field_required('App name')
  ),
  description: Yup.string().required(
    Dictionary.error_messages.description_required
  ),
  webhook_url: Yup.string()
    .nullable()
    .notRequired()
    .test('is_uri', urlValidationErrorMessage, urlValidation),
  callback_url: Yup.string()
    .nullable()
    .notRequired()
    .test('is_uri', urlValidationErrorMessage, urlValidation),
  services: Yup.array().required(
    Dictionary.error_messages.field_required('App services')
  ),
  logo_url: Yup.mixed(),
});
