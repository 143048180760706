import * as Yup from 'yup';
import { Dictionary } from '../../../constants/dictionary';

export const CreateRoleSchema = Yup.object().shape({
  email: Yup.string()
    .email(Dictionary.error_messages.email_invalid)
    .required(Dictionary.error_messages.email_required),
  role: Yup.string().required(
    Dictionary.error_messages.field_required('The member role')
  ),
});
