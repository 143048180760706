import React, { useState } from 'react';
import { useAppDispatch } from '../../../store/customHooks';
import { Link } from 'react-router-dom';

//components
import Input from '../../../components/input-fields/input/input';
import FillButton from '../../../components/common/buttons/button';
import { useFormik } from 'formik';
import { createUserSchema } from './signup-validation-schema';

//images
import { ReactComponent as PasswordHidden } from '../../../assets/icons/content-hidden.svg';
import { ReactComponent as PasswordVisible } from '../../../assets/icons/content-visible.svg';
import urls from '../../../routes/all-routes';
import { trimObjectValues } from '../../../utils/hooks/cleanup';
import { createUserAccountAction } from '../../../store/onboarding/onboarding.actions';
import PasswordStrengthProgress from '../../../components/common/password-strength';
import CustomPhoneInput from '../../../components/input-fields/phone';
import FormWrapper from '../../../components/form/form-wrapper';

export interface UserDetailsProps {
  stepNo: number;
  saveStepStatus: (data?: any) => void;
  prevStep?: (props: any) => void;
}

export default function UserDetails(_props: UserDetailsProps) {
  const [formError, setFormError] = useState('');
  const [passwordVisible, setPasswordVisisble] = useState(false);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      business_name: '',
      phone_number: '',
    },

    validationSchema: createUserSchema,

    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setFormError('');
      await dispatch(createUserAccountAction(trimObjectValues(values)))
        .unwrap()
        .then(() => {
          _props.saveStepStatus(values);
        })
        .catch((error) => {
          setFormError(error);
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });

  return (
    <div>
      <div>
        <h6>Get started</h6>

        <p className="sm">
          Already have an account?{' '}
          <Link to={urls.login.index}>
            <FillButton label="Log In" variant="ghost" />
          </Link>
        </p>
      </div>

      <FormWrapper
        onSubmit={formik.handleSubmit}
        formError={formError}
        dismissFormError={() => {
          setFormError('');
        }}
      >
        <Input
          label="Full Name"
          type="text"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          error={formik.touched.name && formik.errors.name}
          errorMessage={formik.errors.name}
        />

        <Input
          label="Email Address"
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
          errorMessage={formik.errors.email}
        />

        <CustomPhoneInput
          label="Mobile"
          name="phone_number"
          onChange={(value: string) => {
            formik.setFieldValue('phone_number', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.phone_number}
          error={formik.touched.phone_number && formik.errors.phone_number}
          errorMessage={formik.errors.phone_number}
        />

        <Input
          label="Business Name"
          type="text"
          name="business_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.business_name}
          error={formik.touched.business_name && formik.errors.business_name}
          errorMessage={formik.errors.business_name}
        />

        <Input
          label="Password"
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          icon={passwordVisible ? <PasswordVisible /> : <PasswordHidden />}
          iconAction={() => {
            setPasswordVisisble(!passwordVisible);
          }}
          iconClassName="password-icon"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && formik.errors.password}
          errorMessage={formik.errors.password}
        />

        <div>
          <PasswordStrengthProgress password={formik.values.password} />
          <p className="xs">
            Password must be atleast 8 characters long, must contain a number,
            uppercase letter, lowercase letter and symbol(@,.%)
          </p>
        </div>

        <FillButton
          isLoading={formik.isSubmitting}
          label="Continue"
          type="submit"
          disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
          className="auth-button"
        />
      </FormWrapper>
    </div>
  );
}
