import React from 'react';
import Style from './_custom-toast.module.scss';
import { Toast, toast as RootToast } from 'react-hot-toast';
import CloseButton from '../buttons/close-button';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/check-with-border.svg';

interface ToastProps extends Partial<Omit<Toast, 'message'>> {
  message: string;
  onClose?: () => void;
  containerStyles?: string;
}

const CustomToast: React.FC<ToastProps> = (toast) => {
  return (
    <div className={toast.containerStyles}>
      <div
        className={`${Style.custom_toast_container} ${
          toast.type === 'blank' ? Style.custom_toast_info : ''
        }
        ${toast.type === 'success' ? Style.custom_toast_success : ''}
        ${toast.type === 'error' ? Style.custom_toast_error : ''}
        `}
      >
        {toast.type === 'blank' && <InfoIcon />}
        {toast.type === 'success' && (
          <div style={{ width: '1.5rem', height: '1.5rem' }}>
            <SuccessIcon />
          </div>
        )}
        <p>{toast?.message?.toString()}</p>
        <CloseButton
          type={'button'}
          style={{ color: 'inherit' }}
          onClick={() => {
            toast.onClose ? toast.onClose() : RootToast.dismiss(toast.id);
          }}
        />
      </div>
    </div>
  );
};

export default CustomToast;
