import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  loginUrl,
  resetPasswordUrl,
  sendResetPasswordUrl,
} from '../../config/allUrl';
import { getOrgProfileAction } from '../account/account.actions';
import { RootState } from '../store';
import urls from '../../routes/all-routes';

export const loginUserAction = createAsyncThunk<
  any,
  {
    email: string;
    password: string;
    token: string | number;
  },
  { state: RootState }
>(
  'auth/loginUserAction',
  async (
    values: {
      email: string;
      password: string;
      token: string | number;
    },
    ThunkApi
  ) => {
    const { auth } = ThunkApi.getState();
    try {
      const loginPayload = {
        email: values.email,
        password: values.password,
      };
      const twofaPayload = {
        token: values.token,
        loginKey: auth.loginKey,
      };

      let res;

      if (auth.loginKey !== '' && values.token !== '') {
        res = await callApi.post(`${loginUrl}/2fa`, twofaPayload);
      } else {
        res = await callApi.post(`${loginUrl}`, loginPayload);
      }

      if (res) {
        const data = res.data;

        if (data.is_twofa_enabled === true) {
          return { twofa: data.is_twofa_enabled, ...data };
        }

        sessionStorage.setItem(
          'adjutor-auth',
          JSON.stringify({
            sessionId: data.session_id,
            access_token: data.token,
            userId: data.id,
            org_id: data.last_org_id,
          })
        );

        ThunkApi.dispatch(getOrgProfileAction()); //For Check if Last Org ID
        return { data: res.data, org_id: data.last_org_id };
      }
    } catch (error: any) {
      const message: string = error?.response?.data?.message ?? error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  'auth/ResetPasswordAction',
  async (
    values: { new_password: string; email: string; token: string },
    ThunkApi
  ) => {
    const payload = {
      new_password: values.new_password,
      email: values.email,
    };
    try {
      const res: any = await callApi.post(
        resetPasswordUrl(values.token),
        payload
      );
      return res;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const sendResetPasswordMailAction = createAsyncThunk(
  'auth/sendResetPasswordMailAction',
  async (values: { email: string }, ThunkApi) => {
    try {
      const res = await callApi.post(sendResetPasswordUrl, values);
      return res;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const logoutAction = () => {
  sessionStorage.clear();
  window.location.replace(urls.login.index);
};
