import * as Yup from 'yup';
import { Dictionary } from '../../../constants/dictionary';

export const CreateModelSchema = Yup.object().shape({
  name: Yup.string().required(Dictionary.error_messages.field_required),
  description: Yup.string().required(
    Dictionary.error_messages.description_required
  ),
  decision_setting: Yup.mixed().required('Select a Module'),
});
