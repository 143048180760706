import React from 'react';
import '../_common-input-styles.scss';
import './_phone-number-input.scss';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useIPData from '../../../hooks/useIPData';

export interface InputProps extends PhoneInputProps {
  name?: string;
  label?: string;
  error?: boolean | string;
  success?: boolean | string;
  successMessage?: string;
  errorMessage?: string;
  inputContainerStyle?: React.CSSProperties;
}

const CustomPhoneInput: React.FC<InputProps> = (props) => {
  const {
    label,
    error,
    errorMessage,
    successMessage,
    success,
    inputContainerStyle,
    ...rest
  } = props;

  const { data: IPData } = useIPData();

  return (
    <div style={inputContainerStyle} className="input-container">
      <div
        className={`input-field-container  ${error && 'input-error'} ${
          success && 'input-success'
        }`}
      >
        {label && <label className={`input-label active`}>{label}</label>}

        <PhoneInput
          {...rest}
          inputProps={{
            name: rest.name,
            required: true,
            ...rest.inputProps,
          }}
          onBlur={rest.onBlur}
          country={IPData?.country_code?.toLowerCase?.() ?? 'ng'}
          containerClass="phoneContainer"
          inputClass={`phoneInput`}
          value={props.value}
          onChange={props.onChange}
          enableAreaCodes={false}
          countryCodeEditable={false}
          priority={{ ng: 0 }}
          masks={{ ng: '... ... ....' }}
        />
      </div>

      {error && errorMessage && (
        <label className="error-message">{errorMessage}</label>
      )}
      {success && <label className="success-message">{successMessage}</label>}
    </div>
  );
};

export default CustomPhoneInput;
