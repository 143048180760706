import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import { oraculiUrl } from '../../config/allUrl';
import { toast } from 'react-hot-toast';
import { RootState } from '../store';
import { IModel } from './decision-model.interface';
import { defaultErrorMessage } from '../../utils/helper';

export const getDecisionModelAggregate = createAsyncThunk(
  'business/getAllModelsAggregateAction',
  async (pageDetails: { limit: number }, ThunkApi) => {
    try {
      const res = await callApi.get(
        `${oraculiUrl}?page=0&limit=${pageDetails.limit}`
      );

      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getAllModelsAction = createAsyncThunk(
  'business/getAllModelsAction',
  async (pageDetails: { page: number; limit: number }, ThunkApi) => {
    try {
      const res = await callApi.get(
        `${oraculiUrl}?page=${pageDetails.page}&limit=${pageDetails.limit}`
      );

      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getSingleModelAction = createAsyncThunk(
  'business/getSingleModelAction',
  async (id: string, ThunkApi) => {
    try {
      const res = await callApi.get(`${oraculiUrl}/${id}`);
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getConfigModelAction = createAsyncThunk(
  'business/getConfigModelAction',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(`${oraculiUrl}/configuration`);
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const createModelAction = createAsyncThunk(
  'business/createModelAction',
  async (data: Partial<IModel>, ThunkApi) => {
    try {
      const res = await callApi.post(`${oraculiUrl}/`, data);

      return res.data;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const duplicateModelAction = createAsyncThunk<
  { res: string },
  number,
  { state: RootState }
>('business/duplicateModelAction', async (id: number, ThunkApi) => {
  await ThunkApi.dispatch(getSingleModelAction(`${id}`));
  const { decisionModel } = ThunkApi.getState();
  const focusModel = decisionModel.focusModel;

  const payload = {
    name: `${focusModel.name} Copy`,
    description: focusModel.description,
    decision_setting: focusModel.decision_setting,
    offer_setting: focusModel.offer_setting,
    settings: focusModel.settings.map((setting: any) => {
      return {
        setting: setting.setting,
        decision_type: setting.decision_type,
      };
    }),
  };
  try {
    const res = await callApi.post(`${oraculiUrl}/`, payload);

    return res.data;
  } catch (error: any) {
    const message= error?.message;
    toast.error(message ?? defaultErrorMessage);
    return ThunkApi.rejectWithValue(message);
  }
});

export const updateModelAction = createAsyncThunk(
  'business/updateModelAction',
  async (data: { payload: Partial<IModel>; id: number }, ThunkApi) => {
    try {
      const res = await callApi.put(`${oraculiUrl}/${data.id}`, data.payload);

      if (res) {
        ThunkApi.dispatch(getSingleModelAction(`${data.id}`));
      }
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateModelSettingAction = createAsyncThunk<
  any,
  { payload: Partial<IModel>; id: number; modelsettingid: number },
  { state: RootState }
>(
  'business/updateModelSettingAction',
  async (
    data: { payload: Partial<IModel>; id: number; modelsettingid: number },
    ThunkApi
  ) => {
    const { auth } = ThunkApi.getState();
    const org_id = auth.org_id;
    try {
      const res = await callApi.put(
        `${oraculiUrl}/${data.id}/${data.modelsettingid}?org_id=${org_id}`,
        data.payload
      );
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const deleteModelAction = createAsyncThunk(
  'business/deleteModelAction',
  async (id: number, ThunkApi) => {
    try {
      await callApi.delete(`${oraculiUrl}/${id}/delete`);
      return id;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const activateModelAction = createAsyncThunk(
  'business/activateModelAction',
  async (id: number, ThunkApi) => {
    try {
      const res = await callApi.put(`${oraculiUrl}/${id}/activate`);
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const deactivateModelAction = createAsyncThunk(
  'business/deativateModelAction',
  async (id: number, ThunkApi) => {
    try {
      const res = await callApi.put(`${oraculiUrl}/${id}/deactivate`);
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

// getModels(data?) {
//     const page = data && data.page ? `page=${data.page}` : "page=0";
//     const limit = data && data.limit ? `limit=${data.limit}` : "limit=10";
//     // const org = org_id ? `?org_id=${org_id}` : ''
//     // return this.http.get(`${environment.baseUrl}/admin/oraculi${org}`)
//     return this.http.get(
//       `${environment.baseUrl}/admin/oraculi?${page}&${limit}`
//     );
//   }

//   getDefault(org_id) {
//     const org = `?org_id=${org_id}`;
//     return this.http.get(`${environment.baseUrl}/admin/oraculi${org}`);
//   }

//   getModel(id) {
//     return this.http.get(`${environment.baseUrl}/admin/oraculi/${id}`);
//   }

//   updateModel(id, data, org_id?) {
//     const org = org_id ? `?org_id=${org_id}` : "";
//     return this.http.put(
//       `${environment.baseUrl}/admin/oraculi/${id}${org}`,
//       data
//     );
//   }

//   updateSettings(id, modelsettingid, org_id, data) {
//     return this.http.put(
//       `${environment.baseUrl}/admin/oraculi/${id}/${modelsettingid}?org_id=${org_id}`,
//       data
//     );
//   }

//   createModel(data) {
//     return this.http.post(`${environment.baseUrl}/admin/oraculi/`, data);
//   }

//   activateModel(id) {
//     return this.http.put(
//       `${environment.baseUrl}/admin/oraculi/${id}/activate`,
//       {}
//     );
//   }

//   deactivateModel(id) {
//     return this.http.put(
//       `${environment.baseUrl}/admin/oraculi/${id}/deactivate`,
//       {}
//     );
//   }

//   deleteModel(id) {
//     return this.http.delete(
//       `${environment.baseUrl}/admin/oraculi/${id}/delete`
//     );
//   }
