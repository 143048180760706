import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  organizationEndUrl,
  organizationUrlV2,
  postUploadPhotoUrl,
  organizationUrl,
  postUploadFileUrl,
  teamMembersUrl,
  accountUrl,
  getSwitchStore,
} from '../../config/allUrl';
import { IEditAccountDetails } from '../../pages/settings/Account Management/edit-account.interface';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../utils/helper';
import urls from '../../routes/all-routes';

export const getOrgProfileAction = createAsyncThunk(
  'settings/getOrgProfileAction',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(organizationEndUrl);
      return res.data;
    } catch (error: any) {
      const message = error?.message;

      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getOrgDocumentsAction = createAsyncThunk(
  'settings/getOrgDocumentsAction',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(`${organizationUrlV2}/document-types`);
      return res.data;
    } catch (error: any) {
      const message = error?.message;

      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const editOrgProfileAction = createAsyncThunk(
  'settings/editOrgProfileAction',
  async (data: IEditAccountDetails, ThunkApi) => {
    try {
      const res = await callApi.put(`${organizationUrl}`, data);
      return res.data;
    } catch (error: any) {
      const message = error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const deleteOrgAction = createAsyncThunk(
  'settings/deleteOrgAction',
  async ({ token, org_id }: { org_id: number; token: string }, ThunkApi) => {
    try {
      const res = await callApi.delete(`${organizationUrl}/${org_id}`, {
        data: { token },
      });
      return res.data;
    } catch (error: any) {
      const message = error?.message;

      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateOrgLogoAction = createAsyncThunk(
  'settings/updateDocumentAction',
  async (values: { file: any; logo_type: 'short' | 'long' }, ThunkApi) => {
    const file = values.file;

    let formData;
    if (file) {
      formData = new FormData();
      formData.append('file', file);
    }

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      let postRes = '';
      if (formData) {
        const uploadRes = await callApi.post(
          postUploadPhotoUrl,
          formData,
          config
        );
        postRes = uploadRes.data.url;
      }

      const res = await callApi.put(`${organizationUrl}`, {
        logo_url: postRes,
        type: values.logo_type,
      });

      return res.data;
    } catch (error: any) {
      const message = error?.message;

      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateOrgDocumentAction = createAsyncThunk(
  'settings/updateLogoAction',
  async (values: { file: any; doc_id: number }, ThunkApi) => {
    const doc_id = values.doc_id;
    const file = values.file;

    const formData = new FormData();
    formData.append('file', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      let postRes = null;
      if (file.type.includes('image')) {
        postRes = await callApi.post(postUploadPhotoUrl, formData, config);
      } else {
        postRes = await callApi.post(postUploadFileUrl, formData, config);
      }
      await callApi.post(`${organizationUrlV2}/document-types/${doc_id}`, {
        logo_url: postRes?.data.url,
      });

      ThunkApi.dispatch(getOrgProfileAction());
    } catch (error: any) {
      const message = error?.message;

      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateUserDetailsAction = createAsyncThunk(
  'profile/updateUserDetailsAction',
  async (values: { full_name: string; phone_number: string }, ThunkApi) => {
    try {
      const res = await callApi.put(teamMembersUrl, values);
      return res.data;
    } catch (error: any) {
      const message = error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const changePasswordAction = createAsyncThunk(
  'profile/changePasswordAction',
  async (values: { old_password: string; new_password: string }, ThunkApi) => {
    try {
      const res = await callApi.post(`${accountUrl}/password`, values);
      return res;
    } catch (error: any) {
      const message = error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const switchOrganizationAction = createAsyncThunk(
  'profile/switchOrganizationAction',
  async (org_id: number, ThunkApi) => {
    try {
      const res = await callApi.get(getSwitchStore(org_id));

      sessionStorage.clear();

      sessionStorage.setItem(
        'adjutor-auth',
        JSON.stringify({
          sessionId: res.data.session_id,
          access_token: res.data.token,
          userId: res.data.id,
          org_id: res.data.last_organization.id,
        })
      );

      ThunkApi.dispatch(getOrgProfileAction())
        .unwrap()
        .then(() => {
          window.location.replace(urls.home.index);
        }); //For Check if Last Org ID

      return res;
    } catch (error: any) {
      const message = error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateUserProfileImgAction = createAsyncThunk(
  'profile/updateUserProfileImgAction',
  async (
    values: {
      full_name: string;
      phone_number: string;
      raw_profile_image?: any;
    },
    ThunkApi
  ) => {
    let payload: {
      full_name: string;
      phone_number: string;
      image_url?: string;
    } = {
      full_name: values.full_name,
      phone_number: values.phone_number,
    };

    try {
      if (values?.raw_profile_image) {
        let formData = new FormData();
        formData.append('file', values.raw_profile_image);

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        const postRes = await callApi.post(
          postUploadPhotoUrl,
          formData,
          config
        );

        payload.image_url = postRes.data.url;
      }

      const res = await callApi.put(teamMembersUrl, payload);
      return res.data;
    } catch (error: any) {
      const message = error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
