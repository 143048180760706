import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi, saveAccessToken } from '../../config/apiSetup';
import {
  signupUrl,
  updateBuinessBankUrl,
  updateBusinessAddressUrl,
  updateBusinessDetailsUrl,
  businessKYCUrl,
  postVerifyBankAccount,
  getOnboardingInfoUrl,
  updateOrganizationDetailsUrl,
  resendOnboardingMailUrl,
} from '../../config/allUrl';
import {
  ICompleteBusinessAddress,
  ICompleteBusinessBank,
  ICompleteBusinessProfile,
  ISignUp,
} from './onboarding.interface';
import { toast } from 'react-hot-toast';
import { RootState } from '../store';
import { defaultErrorMessage } from '../../utils/helper';

export const createUserAccountAction = createAsyncThunk(
  'auth/SignupUserAction',
  async (values: ISignUp, ThunkApi) => {
    try {
      const payload = {
        name: values.name,
        password: values.password,
        email: values.email,
        phone_number: values.phone_number,
        locale: navigator.language,
        business_name: values.business_name,
      };
      const res = await callApi.post(signupUrl, payload);

      return {
        ...res,
        email: payload.email,
      };
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateBusinessDetailsAction = createAsyncThunk(
  'auth/UpdateBusinessDetailsAction',
  async (values: ICompleteBusinessProfile, ThunkApi) => {
    const { onboarding }: any = ThunkApi.getState();
    try {
      const res = await callApi.put(updateBusinessDetailsUrl(onboarding.id), {
        ...values,
      });
      return res.data;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateBusinessAddressAction = createAsyncThunk(
  'auth/UpdateBusinessAddressAction',
  async (values: Partial<ICompleteBusinessAddress>, ThunkApi) => {
    try {
      const res = await callApi.put(updateBusinessAddressUrl, values);
      return res;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateBusinessKYCAction = createAsyncThunk(
  'auth/UpdateBusinessKYCAction',
  async (values: any, ThunkApi) => {
    const { onboarding }: any = ThunkApi.getState();
    const formData = new FormData();
    for (let key in values) {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    }
    try {
      const res = await callApi.put(businessKYCUrl(onboarding.id), formData);
      return res;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const updateBusinessBankActions = createAsyncThunk<
  {
    res: {
      currentStep: number;
    };
  },
  ICompleteBusinessBank,
  { state: RootState }
>(
  'auth/UpdateBusinessBankAction',
  async (values: ICompleteBusinessBank, ThunkApi) => {
    const { onboarding } = ThunkApi.getState();
    try {
      const verifyBank = await callApi.post(postVerifyBankAccount, values);
      if (verifyBank.message !== 'Account is valid') {
        const message = 'Please enter a valid bank account';
        return ThunkApi.rejectWithValue(message);
      }
      const payload = {
        ...values,
        slug: onboarding.short_name,
        bvn: verifyBank.data.bvn,
      };
      const res = await callApi.put(updateBuinessBankUrl, payload);
      return res;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getOnboardingDetailsActions = createAsyncThunk(
  'onboarding/getOnboardingDetailsActions',
  async (onboarding_token: any, ThunkApi) => {
    try {
      await saveAccessToken(onboarding_token);
      const onboardingRes = await callApi.get(getOnboardingInfoUrl);

      if (onboardingRes?.data.id) {
        await callApi.put(updateOrganizationDetailsUrl, {
          language: 'en', // Language defaults to english
          meta: JSON.stringify({
            type: 'adjutor',
          }),
        });

        await callApi.put(updateBuinessBankUrl);
      }

      return onboardingRes;
    } catch (error: any) {
      const message= error?.message;
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const resendOnboardingMailAction = createAsyncThunk(
  'auth/ResendOnboardingMailAction',
  async (email: string, ThunkApi) => {
    try {
      const res = await callApi.post(resendOnboardingMailUrl, {
        email,
      });
      return res;
    } catch (error: any) {
      const message= error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
