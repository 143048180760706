import * as Yup from 'yup';
import { PasswordSchema } from '../../../utils/helper';
import { Dictionary } from '../../../constants/dictionary';

export const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(Dictionary.error_messages.email_invalid)
    .required(Dictionary.error_messages.email_required),
});

export const ResetPasswordSchema = Yup.object().shape({
  new_password: PasswordSchema,
});
