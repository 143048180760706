import React, { CSSProperties } from 'react';
import { ButtonProps } from './button.interface';
import { ReactComponent as BackIcon } from '../../../assets/icons/cancel.svg';
import Style from './_close-button.module.scss';
import IconButton from './icon-button';

interface Props extends Pick<ButtonProps, 'onClick' | 'type'> {
  animate?: boolean;
  style?: CSSProperties;
}

function CloseButton(props: Props) {
  return (
    <IconButton className={Style.close_btn} {...props} icon={<BackIcon />} />
  );
}

export default CloseButton;
