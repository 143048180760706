import React, { useState, useEffect } from 'react';
import './_account-management.scss';

import Card from '../../../components/common/cards/cards';
import Table from '../../../components/table/table';
import TableRow from '../../../components/table/tablebody';

//icons
import DragAndDrop from '../../../components/drag-and-drop/drag-and-drop';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';

//icon
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
import MobileTable from '../../../components/table/mobile-table/mobile-table';
import MobileTableRow from '../../../components/table/mobile-table/mobile-tablebody';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import EditCompanyPane from './edit-account-details-pane';
import {
  getOrgDocumentsAction,
  getOrgProfileAction,
  updateOrgDocumentAction,
  updateOrgLogoAction,
} from '../../../store/account/account.actions';
import Modal from '../../../components/common/modal/modal';
import { fullDateFormat } from '../../../utils/formatter';
import PageLoading from '../../../components/page-loading/pageLoading';
import PermissionsGate from '../../../utils/hooks/permission-gate';
import { ADJUTOR_SCOPES } from '../../../utils/hooks/permission-maps';
import { resetUpdatingAccount } from '../../../store/account/account.reducer';
import CardHeader from '../../../components/common/cards/card-header';
import CardBody from '../../../components/common/cards/card-body';
import UploadImageModal from '../../../components/upload-image';
import IconButton from '../../../components/common/buttons/icon-button';
import ExternalLink from '../../../components/common/text/external-link';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';
import ModalFooter from '../../../components/common/modal/modal-footer';
import Chip from '../../../components/common/chip/chip';
import { requestColorMatch } from '../../../utils/helper';

function AccountManagement() {
  const [openEditPane, setOpenEditPane] = useState(false);
  const dispatch = useAppDispatch();
  const orgProfile = useAppSelector((state) => state.account);

  const companyProfile = orgProfile.current_organization_profile;

  const { isDesktop } = useScreenSizeHook();

  useEffect(() => {
    dispatch(getOrgProfileAction());
    dispatch(getOrgDocumentsAction());
  }, []);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [documentToUpload, setDocumentToUpload] = useState(null);
  const [focusedDocument, setFocusedDocument] = useState<number | null>(null);

  const uploadDocumentAction = () => {
    dispatch(
      updateOrgDocumentAction({
        file: documentToUpload,
        doc_id: focusedDocument || 0,
      })
    )
      .unwrap()
      .then(() => {
        cancelUploadModal();
      });
  };

  const cancelUploadModal = () => {
    setShowUploadModal(!showUploadModal);
    setFocusedDocument(null);
    setDocumentToUpload(null);
  };

  const documentTableMore = (doc_type_id: number) => {
    const doc = companyProfile.documents.find((doc) => {
      return doc.kyc_requirement_id === doc_type_id;
    });

    if (
      ['approved', 'pending'].includes(doc?.kyc_status?.toLowerCase() || '')
    ) {
      return null;
    }

    return (
      <IconButton
        icon={<UploadIcon />}
        onClick={() => {
          setShowUploadModal(!showUploadModal);
          setFocusedDocument(doc_type_id);
        }}
        className="icon"
        variant="ghost"
      />
    );
  };

  return (
    <div>
      {!orgProfile.full_name &&
      (orgProfile.isLoading ||
        orgProfile.isDocumentTypesLoading === 'loading') ? (
        <PageLoading loadingMessage="Getting Your Organization Profile" />
      ) : (
        <>
          <Card className="change-logo-card">
            <CardBody className="file-upload-container">
              <div className="logo-section">
                <UploadImageModal
                  permissions={[ADJUTOR_SCOPES.canEditAccountInfo]}
                  value={companyProfile?.logo_url ?? ''}
                  title="Full logo"
                  isLoading={
                    orgProfile.isUpdatingAccount === 'loading.long-org-logo'
                  }
                  modal_description={
                    'This is the image displayed on your web app login page. You can upload an image of your choice from your device.'
                  }
                  uploadAction={(image, onSuccess) => {
                    dispatch(
                      updateOrgLogoAction({
                        file: image,
                        logo_type: 'long',
                      })
                    )
                      .unwrap()
                      .then(() => {
                        onSuccess();
                      })
                      .catch();
                  }}
                  resetAction={(onSuccess) => {
                    dispatch(
                      updateOrgLogoAction({
                        file: '',
                        logo_type: 'long',
                      })
                    )
                      .unwrap()
                      .then(() => {
                        onSuccess();
                      })
                      .catch();
                  }}
                />
              </div>

              <div className="logo-section">
                <UploadImageModal
                  permissions={[ADJUTOR_SCOPES.canEditAccountInfo]}
                  value={companyProfile?.short_logo_url ?? ''}
                  title="Short logo"
                  isLoading={
                    orgProfile.isUpdatingAccount === 'loading.short-org-logo'
                  }
                  modal_description={
                    'This is the image displayed on your web app favicon page. You can upload an image of your choice from your device.'
                  }
                  uploadAction={(image, onSuccess) => {
                    dispatch(
                      updateOrgLogoAction({
                        file: image,
                        logo_type: 'short',
                      })
                    )
                      .unwrap()
                      .then(() => {
                        onSuccess();
                      })
                      .catch();
                  }}
                  resetAction={(onSuccess) => {
                    dispatch(
                      updateOrgLogoAction({
                        file: '',
                        logo_type: 'short',
                      })
                    )
                      .unwrap()
                      .then(() => {
                        onSuccess();
                      })
                      .catch();
                  }}
                />
              </div>
            </CardBody>
          </Card>

          <Card
            className="account-management-card"
            cardHeading={
              <CardHeader
                title="Company Details"
                collapsible
                additiionalButton={
                  <PermissionsGate scopes={[ADJUTOR_SCOPES.canEditAccountInfo]}>
                    <FillButton
                      label="Edit"
                      variant="ghost"
                      onClick={() => {
                        setOpenEditPane(!openEditPane);
                      }}
                    />
                  </PermissionsGate>
                }
              />
            }
          >
            <CardBody className="detail-row">
              <div className="detail-field">
                <h6 className="field-title">Name</h6>
                <p>{companyProfile?.name || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">phone number</h6>
                <p>
                  {companyProfile?.support_phone ? (
                    <ExternalLink
                      href={`tel:${companyProfile?.support_phone}`}
                      label={companyProfile?.support_phone}
                    />
                  ) : (
                    '-'
                  )}
                </p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">support email</h6>
                <p>
                  {companyProfile?.support_email ? (
                    <ExternalLink
                      href={`mailto:${companyProfile?.support_email}`}
                      label={companyProfile?.support_email}
                    />
                  ) : (
                    '-'
                  )}
                </p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">primary email</h6>
                <p>
                  {companyProfile?.primary_email ? (
                    <ExternalLink
                      href={`mailto:${companyProfile?.primary_email}`}
                      label={companyProfile?.primary_email}
                    />
                  ) : (
                    '-'
                  )}
                </p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">website</h6>
                <p>
                  {companyProfile?.website_url ? (
                    <ExternalLink
                      href={`https://${companyProfile?.website_url}`}
                      label={companyProfile?.website_url}
                    />
                  ) : (
                    '-'
                  )}
                </p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">rc number</h6>
                <p>{companyProfile?.rc_number || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">address</h6>
                <p>{companyProfile?.address || '-'}</p>
              </div>

              <div className="detail-field">
                <h6 className="field-title">App URL</h6>
                <p>
                  <a
                    href={`https://${companyProfile?.domain}`}
                    target="_blank"
                    rel="noreferrer noopenner"
                  >
                    {(
                      <FillButton
                        label={companyProfile?.domain}
                        variant="ghost"
                      />
                    ) || '-'}
                  </a>
                </p>
              </div>
            </CardBody>

            <EditCompanyPane
              isOpen={openEditPane}
              onClose={() => {
                setOpenEditPane(!openEditPane);
              }}
              initialValues={companyProfile}
            />
          </Card>

          <Card className="company-details-card">
            <div>
              {isDesktop ? (
                <Table
                  title="Company documents"
                  tableHeader={[
                    'Document Type',
                    'Date Submitted',
                    'Document Status',
                  ]}
                  more={true}
                  className="document-type-table"
                  isEmpty={orgProfile?.document_types.length === 0}
                  emptyStateComponent={<></>}
                >
                  <>
                    {orgProfile?.document_types
                      .filter((item) => {
                        return (
                          item?.id !==
                          companyProfile?.documents.find((doc) => {
                            return doc.kyc_requirement_id === item.id;
                          })?.kyc_requirement_id
                        );
                      })
                      .map((item) => {
                        return (
                          <TableRow
                            key={item.id}
                            more={true}
                            moreOption={documentTableMore(item.id)}
                          >
                            <td className="endpoint-field">
                              {item?.code_description}
                            </td>

                            <td>-</td>

                            <td>
                              <Chip
                                label={'Pending Upload'}
                                data-status={requestColorMatch(
                                  'Pending Upload'
                                )}
                              />
                            </td>
                          </TableRow>
                        );
                      })}
                  </>

                  <>
                    {companyProfile?.documents
                      .filter((item) => {
                        return orgProfile.document_types.some((object2) => {
                          return (
                            item?.definition.additional_code?.toLowerCase() !==
                            object2.additional_code?.toLowerCase()
                          );
                        });
                      })
                      ?.map((item) => {
                        return (
                          <TableRow
                            key={item.id}
                            moreOption={documentTableMore(
                              item.kyc_requirement_id
                            )}
                          >
                            <td className="endpoint-field">
                              {item?.definition.code_description}
                            </td>

                            <td>{fullDateFormat(item.created_on) || '-'}</td>

                            <td>
                              <Chip
                                label={item?.kyc_status}
                                data-status={requestColorMatch(
                                  item?.kyc_status
                                )}
                              />
                            </td>
                          </TableRow>
                        );
                      })}
                  </>
                </Table>
              ) : (
                <MobileTable
                  title="Company’s Documents"
                  className="endpoint-pricing-table"
                  isEmpty={orgProfile?.document_types.length === 0}
                  emptyStateComponent={<></>}
                >
                  <>
                    {orgProfile?.document_types
                      .filter((item) => {
                        return (
                          item?.id !==
                          companyProfile?.documents.find((doc) => {
                            return doc.kyc_requirement_id === item.id;
                          })?.kyc_requirement_id
                        );
                      })
                      .map((item) => {
                        return (
                          <MobileTableRow
                            key={item.id}
                            more={true}
                            moreOption={documentTableMore(item.id)}
                          >
                            <div className="field-container endpoint-field">
                              <span className="field-title">
                                Document Type:{' '}
                              </span>
                              {item.code_description}
                            </div>

                            <div className="field-container ">
                              <span className="field-title">Type: </span>
                              {item.type ? item.type : '-'}
                            </div>

                            <div className="field-container price-field">
                              <span className="field-title">Approved On: </span>
                              {item.approved_on}
                            </div>
                          </MobileTableRow>
                        );
                      })}
                  </>

                  <>
                    {companyProfile.documents
                      .filter((item) => {
                        return orgProfile.document_types.some((object2) => {
                          return (
                            item?.definition.code_description !==
                            object2.code_description
                          );
                        });
                      })
                      .map((item) => {
                        return (
                          <MobileTableRow key={item.id}>
                            <div className="field-container endpoint-field">
                              <span className="field-title">
                                Document Type:{' '}
                              </span>
                              {item?.definition?.code_description}
                            </div>

                            <div className="field-container ">
                              <span className="field-title">Type: </span>
                              {item.kyc_status || '-'}
                            </div>

                            <div className="field-container price-field">
                              <span className="field-title">
                                Date Submitted:{' '}
                              </span>
                              {fullDateFormat(item.created_on) || '-'}
                            </div>
                          </MobileTableRow>
                        );
                      })}
                  </>
                </MobileTable>
              )}
            </div>
          </Card>

          <Modal
            isOpen={showUploadModal}
            title={'Upload Document'}
            onClose={cancelUploadModal}
            className="upload-document-modal"
            modalFooter={
              <ModalFooter>
                <FillButton
                  variant="outlined"
                  onClick={cancelUploadModal}
                  className="close-button"
                  colorVariant={ButtonColor.neutral}
                  label="Cancel"
                />

                <FillButton
                  type="submit"
                  onClick={uploadDocumentAction}
                  isLoading={
                    orgProfile.isDocumentTypesLoading ===
                    'updating.document-upload'
                  }
                  disabled={!documentToUpload}
                  label="Upload"
                  loadingText="Uploading"
                />
              </ModalFooter>
            }
          >
            <div>
              <DragAndDrop
                fileTypes="image/png, image/jpeg, image/jpg, application/pdf"
                setFileToUpload={setDocumentToUpload}
                buttonLabelText="Upload your file"
                value={documentToUpload ?? ''}
                status={
                  orgProfile.isDocumentTypesLoading ===
                  'updating.document-upload'
                    ? 'loading'
                    : orgProfile.isDocumentTypesLoading ===
                        'success.document-upload'
                      ? 'success'
                      : orgProfile.isDocumentTypesLoading ===
                          'error.document-upload'
                        ? 'error'
                        : 'idle'
                }
                reset={documentToUpload === null}
                resetStatusAction={() => {
                  dispatch(resetUpdatingAccount());
                }}
              />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default AccountManagement;
