import { useMemo, useState } from 'react';
import { useAppDispatch } from '../store/customHooks';
import { getInfoFromIp } from '../store/utility/utility.actions';

interface GeoData {
  country: string;
  country_code: string;
  city: string;
  region: string;
  latitude: number;
  longitude: number;
  organization: string;
  timezone: string;
}

function useIPData() {
  const distpatch = useAppDispatch();
  const [data, setData] = useState<GeoData>();

  useMemo(() => {
    distpatch(getInfoFromIp())
      .unwrap()
      .then((data: GeoData) => {
        setData(data);
      });
  }, [distpatch]);

  return { data };
}

export default useIPData;
