import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthLayout from '../../../layout/authLayout/authLayout';
import './_forgot-password.scss';
import { useAppDispatch } from '../../../store/customHooks';
import { resetPasswordAction } from '../../../store/auth/auth.actions';

//components
import { useFormik } from 'formik';
import { ResetPasswordSchema } from './forget-password-validation-schema';
import Input from '../../../components/input-fields/input/input';
import FillButton from '../../../components/common/buttons/button';

//images
import urls from '../../../routes/all-routes';
import PasswordStrengthProgress from '../../../components/common/password-strength';
import toast from 'react-hot-toast';
import FormWrapper from '../../../components/form/form-wrapper';

export default function ResetPasswordPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formError, setFormError] = useState('');

  useEffect(() => {
    if (searchParams.get('token') && searchParams.get('email')) {
      return;
    } else {
      navigate(urls.forgotPassword.index);
    }
  }, [searchParams]);

  const formik = useFormik({
    initialValues: { new_password: '' },

    validationSchema: ResetPasswordSchema,

    onSubmit: async (values: { new_password: string }, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      setFormError('');
      dispatch(
        resetPasswordAction({
          new_password: values.new_password,
          email: searchParams?.get('email') as string,
          token: searchParams?.get('token') as string,
        })
      )
        .unwrap()
        .then(() => {
          toast(
            'Your password was reset successfully, you will redirected to login.',
            { id: 'success-reset-password' }
          );

          setTimeout(() => {
            toast.dismiss('success-reset-password');
            navigate(urls.login.index);
            formikHelpers.setSubmitting(false);
          }, 3000);
        })
        .catch((error) => {
          setFormError(error);
          formikHelpers.setStatus(false);
        });
    },
  });

  return (
    <AuthLayout>
      <div className="forgot-password-page">
        <div>
          <h6>Reset Password</h6>
          <p className="sm">
            Enter a new password, make sure it is something you can easily
            remember.
          </p>
        </div>

        <FormWrapper
          onSubmit={formik.handleSubmit}
          formError={formError}
          dismissFormError={() => {
            setFormError('');
          }}
        >
          <Input
            label="New Password"
            type="password"
            name="new_password"
            value={formik.values.new_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.errors.new_password}
            error={formik.touched.new_password && formik.errors.new_password}
          />

          <div>
            <PasswordStrengthProgress password={formik.values.new_password} />
            <p className="xs">
              Password must be atleast 8 characters long, must contain a number, uppercase letter, lowercase letter
              and symbol(@,.%)
            </p>
          </div>

          <FillButton
            type="submit"
            label="Reset password"
            isLoading={formik.isSubmitting}
            disabled={!(formik.dirty && formik.isValid)}
            className="auth-button"
            loadingText="Resetting password"
          />
        </FormWrapper>
      </div>
    </AuthLayout>
  );
}
