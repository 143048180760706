import * as Yup from 'yup';
import { Dictionary } from '../../../constants/dictionary';

export const createUserSchema = Yup.object().shape({
  email: Yup.string()
    .email(Dictionary.error_messages.email_invalid)
    .required(Dictionary.error_messages.email_required),
  password: Yup.string()
    .min(8, 'Enter at least 8 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      Dictionary.error_messages.password_match
    )
    .required(Dictionary.error_messages.password_required),
  name: Yup.string().required(Dictionary.error_messages.full_name_required),
  business_name: Yup.string().required(
    Dictionary.error_messages.field_required('Your business name')
  ),
  phone_number: Yup.string().required(
    Dictionary.error_messages.phone_number_required
  ),
});

export const createBusinessProfileSchema = Yup.object().shape({
  business_name: Yup.string().required(
    Dictionary.error_messages.field_required('Your business name')
  ),
  role: Yup.string().required(
    Dictionary.error_messages.field_required('Your role in the business')
  ),
  source: Yup.string().required(
    Dictionary.error_messages.field_required('How did you hear about us?')
  ),
});
