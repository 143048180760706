import React, { useState } from 'react';
import './_create-role.scss';
import { ADJUTOR_ROLES } from '../../../utils/hooks/permission-maps';

//form
import { useFormik } from 'formik';
import { CreateRoleSchema } from './create-role-validation-schema';

//components
import Input from '../../../components/input-fields/input/input';
import SlidingPane from '../../../components/slidingpane/slidingpane';
import FillButton, {
  ButtonColor,
} from '../../../components/common/buttons/button';
import Select from '../../../components/input-fields/Select/select';
import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import {
  ISettingsState,
  ITeamMember,
} from '../../../store/settings/settings.interface';
import { manageTeamMembersAction } from '../../../store/settings/settings.actions';
import { resetUpdatingTeamMember } from '../../../store/settings/settings.reducer';
import ModalFooter from '../../../components/common/modal/modal-footer';
import SuccessModal from '../../../components/notice-modal/success-modal';
import FormWrapper from '../../../components/form/form-wrapper';

export interface CreateRoleProps {
  isOpen: boolean;
  onClose: Function;
  member: ITeamMember;
}

function ManageMemberPane(props: CreateRoleProps) {
  const { isOpen, onClose, member } = props;
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const settingState: ISettingsState = useAppSelector(
    (state) => state.settings
  );
  const [formError, setFormError] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: member.id,
      role: member.role_id,
      name: member.full_name,
      email: member.email,
    },

    validationSchema: CreateRoleSchema,

    onSubmit: async (values, formikHelper) => {
      formikHelper.setSubmitting(true);
      await dispatch(
        manageTeamMembersAction({ id: values.id, role_id: values.role })
      )
        .unwrap()
        .then(() => {
          onClose();
          setUpdateSuccess(true);
        })
        .catch((error) => {
          setFormError(error);
        })
        .finally(() => {
          formikHelper.setSubmitting(false);
        });

      settingState.allTeamMemberState = 'updated';
    },
  });

  const roleOptions = ADJUTOR_ROLES.map((role) => {
    return {
      label: role.name,
      value: role.id,
    };
  });

  const filterOptions = (id: number) => {
    return (
      roleOptions?.find((i) => {
        return i.value === id;
      }) ?? null
    );
  };

  const handleReset = () => {
    setFormError('');
    formik.resetForm();
    dispatch(resetUpdatingTeamMember());
  };

  const handlePaneClose = () => {
    onClose();
    handleReset();
  };

  const handleCloseSuccess = () => {
    setUpdateSuccess(false);
    handleReset();
  };

  return (
    <>
      <SlidingPane
        isOpen={isOpen}
        title="Edit member"
        onClose={handlePaneClose}
        footer={
          <ModalFooter>
            <FillButton
              variant="outlined"
              onClick={() => {
                handlePaneClose();
              }}
              className="close-button"
              colorVariant={ButtonColor.neutral}
              label="Close"
            />
            <FillButton
              type="submit"
              onClick={formik.submitForm}
              disabled={!(formik.isValid && formik.dirty)}
              isLoading={formik.isSubmitting}
              label="Update"
            />
          </ModalFooter>
        }
      >
        <FormWrapper
          className="create-role-form"
          onSubmit={formik.handleSubmit}
          formError={formError}
          dismissFormError={() => setFormError('')}
        >
          <div className="form-section">
            <Input
              type="text"
              label="Name"
              placeholder="Name"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              errorMessage={formik.errors.name}
              disabled
            />

            <Input
              type="email"
              label="Email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              errorMessage={formik.errors.email}
              disabled
            />

            <Select
              label="Role"
              selectPlaceholder="Assign Role"
              name="role"
              selectOptions={roleOptions}
              onChange={(e: any) => {
                formik.setFieldValue('role', e.value);
              }}
              value={filterOptions(Number(formik.values.role))}
              error={formik.touched.role && formik.errors.role}
              errorMessage={formik.errors.role}
            />
          </div>
        </FormWrapper>
      </SlidingPane>

      <SuccessModal
        isOpen={updateSuccess}
        onClose={handleCloseSuccess}
        continueAction={handleCloseSuccess}
        title="Success"
        message="Role updated successfully"
        description={`${formik.values.name}'s role has been updated`}
      />
    </>
  );
}

export default ManageMemberPane;
