import React, { useEffect, useRef } from 'react';
import { FormWrapperProps } from './form-wrapper.interface';
import CustomToast from '../common/toast/custom-toast';
import Styles from './_form-wrapper.module.scss';

const FormWrapper: React.FC<FormWrapperProps> = ({
  formError,
  dismissFormError,
  ...props
}) => {
  const errorRef = useRef<HTMLDivElement | null>(null);

  const autoScrollToTop = () => {
    const form = errorRef.current;
    if (!form) return;
    form.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    if (formError) {
      autoScrollToTop();
    }
  }, [formError]);

  return (
    <>
      {!!formError && (
        <div ref={errorRef}>
          <CustomToast
            type="error"
            message={formError}
            onClose={dismissFormError}
            containerStyles={Styles.errorContainerStyles}
          />
        </div>
      )}

      <form {...props}>{props.children}</form>
    </>
  );
};

export default FormWrapper;
