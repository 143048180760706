import React from 'react';
import {
  MultiSelectCheckboxOptionProps,
  
} from './types';
import Checkbox from '../checkbox/checkbox';

type MultiSelectCheckboxElemProps = {
  option: MultiSelectCheckboxOptionProps;
  value: boolean;
  handleChange: (e: any) => void;
};

function MultiSelectCheckboxElement({
  option,
  value,
  handleChange
}: MultiSelectCheckboxElemProps) {
  return (
    <>
      <Checkbox
        key={`${option?.value}`}
        label={
          <>
            <p className="font-600 text-text-primary sm">{option?.label}</p>
            <p style={{ marginTop: '0.125rem' }} className="sm">
              {option?.description}
            </p>
          </>
        }
        name={option?.value}
        id={option?.value.replaceAll(' ', '-')}
        value={value}
        onChange={handleChange}
      />
    </>
  );
}

export default MultiSelectCheckboxElement;
